@use '../../src/styles/mixins/colours';

.SendForm {
    > * {
        padding: 1.3em;
    }

    &.status {
        overflow: auto;
        padding: 0 1em;
    }

    form {
        background-color: #{colours.get-static('colour-bg-highlight-tint-1')};
        background-color: var(--colour-bg-highlight-tint-1);
        display: grid;
        gap: 2em;
        padding-bottom: 2.5em;

        @media ($viewport-is-wide) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                'left-column right-column'
                'left-column right-column';

            .toDetails,
            .fromDetails {
                grid-column: left-column;
            }

            .submitDetails {
                grid-column: right-column;
                grid-row: right-column;
            }
        }

        .why-cant-i-edit-tooltip {
            display: flex;
            justify-content: left;
            column-gap: 5px;
            align-items: center;
            margin: 5px 0;

            .TooltipHover {
                width: fit-content;
                margin: 0;

                .SvgIcon {
                    width: 17px;
                    height: 17px;
                    margin-bottom: -3px;
                }
            }
        }

        .submitDetails {
            .messagePreview {
                .description {
                    font-weight: bold;
                    margin: 0 0 0.5em;
                }

                .messageText {
                    background-color: #{colours.get-static('colour-bg-highlight-tint-2')};
                    background-color: var(--colour-bg-highlight-tint-2);
                    white-space: pre-wrap;
                    padding: 1em;
                    max-height: 13.5em;
                    overflow: auto;

                    a,
                    a:visited {
                        color: inherit;
                    }
                }
            }

            .formControls {
                display: flex;
                justify-content: space-between;
                margin: 1em 0;
            }

            .submitButton {
                background-color: var(--colour-bg-standard);
                color: var(--colour-text-brand);

                .SvgIcon {
                    margin: 0 -4px -2px 5px;

                    path {
                        fill: var(--colour-text-brand);
                    }
                }
            }
        }

        .errorMessage {
            color: #{colours.get-static('colour-text-error')};
            color: var(--colour-text-error);
            font-weight: bold;
        }
    }
}
