@use '../styles/mixins/colours';

.CalloutBox {
    &.askIzzyInfoBox {
        margin: 2em 0;
        padding: 0 1.3em 0.5em;
        border: solid #{colours.get-static('colour-border-standard-callout')} 1px;
        border: solid var(--colour-border-standard-callout) 1px;
    }

    &.nationalHotline {
        margin: 2em 0;
        padding: 0.4em 1.4em 1em;
        background-color: #{colours.get-static('colour-bg-callout-solid')};
        background-color: var(--colour-bg-callout-solid);

        h2 {
            margin: 0.8em 0 0.5em;
        }

        p {
            margin: 0.5em 0;
        }
    }

    &.OnlineSecurity {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        background-color: #{colours.get-static('colour-text-light')};
        background-color: var(--colour-text-light);
        cursor: pointer;

        .h2 {
            font-size: 1.15em;
            font-weight: 500;
        }

        p {
            width: 50%;
            font-size: small;
            text-align: center;

            img:first-of-type {
                width: 42px;
                height: 42px;
                padding: 11px;
                border-radius: 50%;
                background-color: #{colours.get-static('raw-colour-teal')};
                background-color: var(--raw-colour-teal);
            }
        }

        .Contact {
            width: 320px;
        }

        a {
            font-weight: 500;
        }
    }

    .Phone .ContactButton {
        margin: 0.5em 0;

        .Contact-text {
            padding-left: 30px;
        }
    }

    @media (max-width: 768px) {
        .LogoWithTextBox p {
            width: 100%;
        }
    }
}
