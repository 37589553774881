@use '../styles/mixins/colours';

.ListItem {
    display: block;
    position: relative;
    border: 0;
    border-bottom: 1px solid #{colours.get-static('colour-border-list-item')};
    border-bottom: 1px solid var(--colour-border-list-item);
    text-decoration: none;

    /* remove default button styling */
    @at-root {
        button#{&} {
            width: 100%;
            padding: 0;
            background: none;
            font-family: inherit;
            font-size: inherit;
            text-align: left;
        }
    }

    &:not(.has-left-icon) {
        padding-left: 16px;
    }

    &:not(.has-right-icon) {
        padding-right: 16px;
    }

    > div {
        display: flex;
        align-items: center;
        min-height: 56px;

        > .leftIcon {
            display: flex;
            justify-content: center;
            min-width: 58px;

            // Hack until we refactor icons to stop wrapping them in spans
            svg {
                vertical-align: text-bottom;
            }
        }

        > .label {
            flex: 1;
            min-width: 0; /* needed for force flex to collapse this if content is wider than the current space */
            padding-top: 16px;
            padding-bottom: 16px;

            > .primaryText {
                color: #{colours.get-static('colour-text-dark')};
                color: var(--colour-text-dark);
                font-size: 16px;
                line-height: 150%;

                .link-text {
                    color: #{colours.get-static('colour-link')};
                    color: var(--colour-link);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            > .secondaryText {
                color: #{colours.get-static('colour-text-mid')};
                color: var(--colour-text-mid);
                font-size: 14px;
                line-height: 150%;
                overflow: hidden;
            }
        }

        > .rightIcon {
            display: flex;
            justify-content: center;
            min-width: 35px;
            padding-right: 8px;

            input {
                position: absolute;
                top: 16px;
                left: 16px;
                z-index: -99;
            }

            svg.Icon {
                vertical-align: text-bottom;
            }
        }
    }

    &.taller {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 18px;

        .leftIcon {
            top: 15px;
        }
    }

    &.hero {
        > div {
            padding: 0.3em;

            > .label {
                padding-top: 11px;
                padding-bottom: 11px;
            }

            > .label > .primaryText {
                font-size: 18px;
                font-weight: 500;
                line-height: 18px;
            }
        }
    }

    &.Link {
        > div {
            > .label {
                > .secondaryText {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
