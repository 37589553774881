@use '../styles/mixins/colours';

.CollapsedOpeningTimes {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        flex-grow: 1;
    }

    > .Collapser {
        width: 100%;

        &.collapsed {
            width: auto;
        }

        & > .collapser-message {
            display: block;
            font-size: 16px;
            text-align: right;
            white-space: nowrap;

            &:visited {
                color: #{colours.get-static('colour-link')};
                color: var(--colour-link);
            }
        }
    }
}
