@use '../styles/mixins/colours';

.ServicePagePrint {
    background-color: #fff;
    color: #000;
    position: relative;
    padding: 30px;
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50vh;

    .service-page-print-header {
        display: flex;
        justify-content: space-between;

        .date-container {
            font-size: 10px;
        }

        .AskIzzyGreyScaleIcon {
            width: 130px;
            height: 70px;
        }
    }

    .Accessibility {
        font-size: 14px;
        color: var(--raw-colour-pure-black);
    }

    .Address .MapSolidIcon {
        width: 25px;
        height: 25px;
        margin-left: -2px;
        margin-right: 6px;
        margin-top: 3px;
    }

    .my-service-page-phone-container {
        display: flex;
        align-items: center;
        margin-top: 3px;
        margin-bottom: -5px;
    }

    .my-service-page-phone-container .icon {
        width: 30px;
        height: 30px;
        margin-right: 2px;
    }

    .ClockIcon {
        width: 30px;
        height: 30px;
        margin-left: -3px;
        margin-top: 3px;
    }

    .service-info-caption {
        margin-bottom: 20px;
    }

    .Service-Containers {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .Service-Info {
        line-height: 1.5;
        flex: 1;
    }

    .service-page-print-service-provisions {
        padding-right: 15px;
        font-size: 13px;
    }

    .Cost h2 {
        padding-top: 10px;
        color: var(--raw-colour-pure-black);
        font-size: 15px;
    }

    .ServicePane-Info {
        padding: 12px;
        padding-bottom: 5px;
        font-size: 14px;
        flex: 1;

        .BoxedText {
            margin-top: 5px;
            padding: 25px;

            .BoxedText-container {
                padding: 15px;
                padding-top: 5px;
                padding-bottom: 5px;
                background-color: transparent;
                border: 1px var(--colour-border-list-item-gray) solid;

                .Spacer {
                    background-color: var(--colour-border-list-item-gray);
                }
            }
        }
    }

    .Eligibility {
        .eligibility h2 {
            font-size: 15px;
            color: var(--raw-colour-pure-black);
        }

        .eligibility ul li {
            font-size: 14px;
            color: var(--raw-colour-pure-black);
        }
    }

    .email-container {
        margin-left: -11px;
        margin-top: -10px;
        font-size: 14px;
        overflow-wrap: break-word;
        text-decoration: none;

        .EmailIcon {
            margin-left: 3px;
        }
    }

    .web-container {
        font-size: 14px;
        overflow-wrap: break-word;
        margin-top: -10px;
        text-decoration: none;

        .WebsiteIcon {
            width: 45px;
            height: 45px;
            vertical-align: bottom;
            text-decoration: none;
            margin-left: -12px;
        }
    }

    .time-container {
        font-size: 14px;
        overflow-wrap: break-word;
    }

    .Contact a {
        font-size: 14px;
    }

    .Address {
        font-size: 14px;

        .Address-wrapper {
            color: #000;
            font-size: 14px;
            flex-wrap: wrap-reverse;
        }
    }

    .service-page-print-about-title {
        font-size: 16px;
        font-weight: bold;
    }

    .Bottom-container {
        break-inside: avoid;

        .page-print-footer {
            width: 100%;
            display: flex;
            padding: 20px;
            box-sizing: border-box;

            .top-section {
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                text-align: left;
                font-size: 13px;

                .need-an-interpreter {
                    text-align: left;
                }

                .service-page-print-about-title {
                    text-align: left;
                    font-size: 13px;
                }
            }

            .service-page-print-qr {
                text-align: center;
                font-size: 13px;
                font-weight: bold;

                .qr-code {
                    width: 120px;
                }
            }
        }
    }
}
