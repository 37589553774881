@use '../styles/mixins/colours';

.QuestionStepper {
    display: flex;
    align-items: center;
    gap: 1em;

    > .icon svg {
        width: 40px;
        height: 40px;
        display: block; /* required to remove annoying space under icon */
    }

    > .content {
        display: flex;
        flex-direction: column;
        gap: 0.3em;
        flex-grow: 1;

        > h3 {
            font-size: 1.3em;
            margin: 0;
        }

        > .currentProgress {
            display: flex;
            gap: 1em;
            align-items: center;

            .ProgressBar {
                flex-grow: 1;
            }
        }

        @include colours.define(
            'colour-comma-pipe',
            'QuestionStepper',
            colours.lighten('raw-colour-pure-white', 'global', -40%)
        );

        // Remove browser styles for lists
        ol.breadcrumbs {
            padding-left: 0;
            margin: 0;

            li {
                display: inline-block;
                list-style-type: none;
            }
        }

        .clearButton {
            border: 0;
            background: none;
            color: #{colours.get-static('colour-link')};
            color: var(--colour-link);
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        .breadcrumbSpacer {
            color: #{colours.get-static('colour-comma-pipe', 'QuestionStepper')};
            color: var(--colour-comma-pipe);
            margin: 0 0.4em;
            white-space: pre;
        }

        .breadcrumbAnswerSpacer {
            color: #{colours.get-static('colour-comma-pipe', 'QuestionStepper')};
            color: var(--colour-comma-pipe);
        }
    }
}
