@use '../styles/mixins/colours';

.Address {
    position: relative;
    display: flex;

    svg {
        margin-left: -2px;
        margin-right: 6px;
        vertical-align: text-bottom;
    }

    .Address-wrapper {
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);

        &.single-line {
            display: flex;
            align-items: center;
        }

        font-size: 16px;
    }

    .details {
        display: block;
        padding-top: 1em;
    }
}
