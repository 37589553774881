@use '../../src/styles/mixins/colours';

.ShareDirectlyOptions {
    // @include colours.define('colour-button-primary', 'global', 'colour-brand-primary');

    $lightness-button-hover: 10%;

    @include colours.define(
        'colour-button-primary',
        'global',
        colours.lighten('raw-colour-pure-white', 'global', -80%)
    );
    @include colours.define(
        'colour-button-primary-hover',
        'global',
        colours.lighten('colour-button-primary', 'global', 10%),
    );

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 1em;

    .SvgIcon {
        margin: 0 2px -3px 0;
        width: 25px;
    }

    .includeOtherDetails {
        input {
            margin-right: 0.5em;
        }
    }
}
