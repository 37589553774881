@use '../../src/styles/mixins/colours';

.ResultsListPage {
    position: relative;

    .QuestionStepper {
        margin: 1em 20px;
    }

    .AlertBannerList {
        .AlertBannerButton {
            position: relative;
            float: right;
        }
    }

    .progress {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        & > * {
            display: block;
            width: 57px;
            height: 57px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .rightIcon {
        top: 6px;
        width: 40px;
        height: 40px;
    }

    .change-personalisation-container {
        padding: 20px 15px 15px 30px;
        text-align: right;
    }

    .change-personalisation {
        font-weight: bold;

        &:visited {
            color: #{colours.get-static('colour-link')};
            color: var(--colour-link);
        }
    }

    .List {
        padding: 0;
    }

    .ViewOnMapButton {
        background-color: #{colours.get-static('colour-bg-standard-tint-2')};
        background-color: var(--colour-bg-standard-tint-2);
        color: #{colours.get-static('colour-text-mid')};
        color: var(--colour-text-mid);

        .primaryText {
            font-size: 18px;
            line-height: 18px;
        }

        & > div > .leftIcon {
            min-width: 55px;
            padding-left: 10px;
        }
    }

    .moreResultsContainer {
        padding: 20px 0;
        background-color: #{colours.get-static('colour-bg-standard-tint-2')};
        background-color: var(--colour-bg-standard-tint-2);
    }

    .MoreResultsButton {
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        padding: 10px;
        background-color: #{colours.get-static('colour-bg-opposite')};
        background-color: var(--colour-bg-opposite);
        color: #{colours.get-static('colour-text-very-light')};
        color: var(--colour-text-very-light);
        font-size: 16px;
        text-align: center;
    }

    .CrisisHeader {
        margin-right: 16px;
        margin-bottom: 5px;
        margin-left: 16px;
        font-size: 18px;
        font-weight: 500;
    }

    > div > .DebugContainer {
        margin: 1em;
    }

    .ResultsListControls {
        background-color: #{colours.get-static('colour-bg-standard')};
        background-color: var(--colour-bg-standard);
        padding: 5px;

        .Controls {
            background-color: #{colours.get-static('colour-bg-standard')};
            background-color: var(--colour-bg-standard);

            .Dropdown {
                .title {
                    color: #{colours.get-static('colour-brand-primary')};
                    color: var(--colour-brand-primary);
                }

                .optionsContainer {
                    .optionSelect {
                        color: #{colours.get-static('colour-brand-primary')};
                        color: var(--colour-brand-primary);
                        border: solid 1px #{colours.get-static('colour-brand-primary')};
                        border: solid 1px var(--colour-brand-primary);
                        background-color: #{colours.get-static('colour-bg-standard')};
                        background-color: var(--colour-bg-standard);

                        .Icon {
                            path {
                                stroke: #{colours.get-static('colour-brand-primary')};
                                stroke: var(--colour-brand-primary);
                                fill: #{colours.get-static('colour-brand-primary')};
                                fill: var(--colour-brand-primary);
                            }
                        }
                    }

                    .options {
                        outline: solid 1px #{colours.get-static('colour-brand-primary')};
                        outline: solid 1px var(--colour-brand-primary);

                        div {
                            color: #{colours.get-static('colour-brand-primary')};
                            color: var(--colour-brand-primary);
                        }
                    }

                    width: 150px;
                }

                .nativeSelect {
                    color: #{colours.get-static('colour-brand-primary')};
                    color: var(--colour-brand-primary);
                    border: solid 1px #{colours.get-static('colour-brand-primary')};
                    border: solid 1px var(--colour-brand-primary);
                    background-color: #{colours.get-static('colour-bg-standard')};
                    background-color: var(--colour-bg-standard);
                }
            }
        }
    }
}
