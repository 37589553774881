@use '../styles/mixins/colours';

.AddToCompareButton {
    padding: 0;
    background-color: transparent;
    border: 0;
    white-space: nowrap;

    .main-container {
        display: flex;
        align-items: center;

        .text-description {
            color: #{colours.get-static('colour-brand-primary')};
            color: var(--colour-brand-primary);
            font-weight: bolder;
            padding: 0 10px;
        }

        .SvgIcon {
            margin-bottom: -3px;
        }

        .RemoveFromCompareIcon {
            &.has-no-text {
                rect {
                    stroke: #{colours.get-static('colour-border-list-item-mid')};
                    stroke: var(--colour-border-list-item-mid);
                }

                line {
                    stroke: #{colours.get-static('colour-border-list-item-mid')};
                    stroke: var(--colour-border-list-item-mid);
                }
            }
        }
    }
}
