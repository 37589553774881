@use '../styles/mixins/colours';

.AppBarContainer {
    // When we don't have the taper it removes the
    // white space from the top of the header
    padding: 1px 0;
    margin-bottom: 5em;
}

.AppBar {
    $appbar-text-line-height: 42px;

    display: flex;
    position: fixed;
    flex-direction: row;
    justify-content: space-between;
    -ms-flex-pack: justify;

    @media print {
        display: none;
    }

    width: 100%;
    max-width: 800px;
    height: $appbar-height;
    min-height: auto;
    color: #{colours.get-static('colour-text-very-light')};
    color: var(--colour-text-very-light);
    text-overflow: ellipsis;
    z-index: 2;

    .widget-container {
        margin-top: 1.9em;
        margin-right: 1em;
        display: flex;
        align-items: center;

        &.showBar {
            margin: 0;
        }
    }

    img {
        height: 30px;
    }

    .appBarLogo {
        margin: 1em;
    }

    .IconButton {
        height: $appbar-height;
    }

    &.showBar {
        top: 0;
        transition: background-color 0.05s ease;
        justify-content: space-between;
        -ms-flex-pack: justify;
        background-color: #{colours.get-static('colour-brand-primary')};
        background-color: var(--colour-brand-primary);
        box-shadow: 0 0 6px 0 #{colours.get-static('app-bar-shadow')};
        box-shadow: 0 0 6px 0 var(--app-bar-shadow);

        .appBarLogo {
            margin: 10px;
            height: 30px;

            img {
                height: 25px;
            }
        }
    }

    button {
        width: auto;

        .backButton {
            width: 13rem;
        }

        .ChevronBackIcon {
            width: $appbar-height;
            height: $appbar-height;
        }

        span {
            margin-left: -6px;
            float: left;
        }

        .back-label {
            height: $appbar-height;
            margin-top: 1px;
            font-size: 14px;
            font-weight: 500;
            line-height: $appbar-text-line-height;
            text-overflow: ellipsis;
        }
    }
}
