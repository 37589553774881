.ListAllStories {
    display: grid;
    padding: 0;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, 120px);
    grid-template-rows: 120px;
    grid-auto-rows: 100px;

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        text-align: center;
        list-style-type: none;
        word-wrap: anywhere;

        a {
            color: unset;
            text-decoration: unset;
        }
    }
}
