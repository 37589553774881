.AreYouSafe {
    h2,
    h4 {
        font-weight: normal;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin: 0 2px;
        display: inline-block;
    }

    .safety-message {
        width: 75%;
        margin: 40px auto;
        text-align: center;

        span,
        p {
            font-weight: normal;
            font-size: 18px;
        }

        .phone-number {
            white-space: nowrap;
        }
    }

    .PhoneIcon {
        width: 90px;
        height: 90px;
    }

    @media (max-width: 768px) {
        & {
            .safety-message {
                width: 85%;
            }
        }
    }
}
