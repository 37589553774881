@use '../styles/mixins/colours';

.ResultListItemContact {
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    justify-content: flex-end;
    margin-bottom: 30px;

    .ContactButton {
        background-color: var(--colour-brand-primary);
        color: var(--colour-text-very-light);
        border-radius: 5px;
        padding: 10px;
        text-decoration: none;

        .PhoneSolidIcon {
            fill: var(--colour-text-very-light);
            width: 25px;
            height: 25px;
            margin-bottom: -8px;
            margin-top: -15px;
        }
    }

    .contact-text {
        display: flex;
        flex-direction: row;
        column-gap: 3px;

        .number.value {
            white-space: nowrap;
        }
    }

    .contact-other {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 10px;
        margin-top: 5px;

        .contact-comment {
            color: var(--colour-text-mid);
            text-align: right;
        }

        .Link {
            font-weight: bolder;
            color: var(--colour-text-dark);
            width: fit-content;
            white-space: nowrap;
        }

        .DirectionsIcon {
            fill: var(--colour-brand-primary);
        }

        .GoogleMapsLink {
            display: flex;
            align-items: center;
            background-color: var(--colour-button-secondary);
            border: 1px solid var(--colour-border-list-item-mid);
            border-radius: 5px;
            color: var(--colour-text-highlight);
            width: fit-content;
            white-space: nowrap;
            padding: 6px;
            text-decoration: none;
        }
    }
}
