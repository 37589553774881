@use '../styles/mixins/colours';

@media print {
    path,
    circle {
        fill: #{colours.get-static('raw-colour-pure-black')};
        fill: var(--raw-colour-pure-black);
    }
}

.Icon {
    transition: none;
}

svg.ColoredIcon {
    &.allow-override-color.icon-fg-color {
        path,
        circle {
            fill: #{colours.get-static('raw-colour-rich-purple')};
            fill: var(--raw-colour-rich-purple);
        }
    }
}

svg.Icon {
    &.inline-icon {
        width: 30px;
        height: 30px;
        vertical-align: middle;
    }

    &.small {
        width: 40px;
        height: 40px;
        vertical-align: middle;
    }

    &.medium {
        width: 36px;
        height: 36px;
    }

    &.big {
        width: 42px;
        height: 42px;
    }
}

.ChevronIcon {
    top: 2px;
    width: 35px;
    height: 35px;
    padding-right: 0;

    path,
    circle {
        fill: #{colours.get-static('colour-interactive-icon')};
        fill: var(--colour-interactive-icon);
    }
}

.ChevronBackIcon {
    path,
    circle {
        fill: #{colours.get-static('raw-colour-pure-white')};
        fill: var(--raw-colour-pure-white);
    }
}

.CheckboxSelectedIcon,
.CheckboxUnselectedIcon,
.RadioSelectedIcon,
.RadioUnselectedIcon {
    path,
    circle {
        fill: #{colours.get-static('colour-interactive-icon')};
        fill: var(--colour-interactive-icon);
    }
}

.CheckboxSelectedIcon {
    path,
    circle {
        fill: #{colours.get-static('raw-colour-pure-white')};
        fill: var(--raw-colour-pure-white);
        stroke: #{colours.get-static('colour-interactive-icon')};
        stroke: var(--colour-interactive-icon);
        stroke-width: 2px;
    }
}

.CrossIcon {
    top: -6px;
}

.LoadingIcon,
.RadioUnselectedIcon,
.RadioSelectedIcon {
    top: -2px;
}

.LoadingIcon {
    animation: rotation 3s infinite linear;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}
