.StaticPage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .AppBar {
        justify-content: space-between;
    }
}

.StaticPage,
.PersonalisationPage {
    .body {
        flex: 1;
        width: 90%;
        margin: auto;

        /* FIXME: autoprefixer should be taking care of this */
        -ms-flex-preferred-size: auto;  /* Needed for IE10 */

        h3 {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .acknowledgement {
            font-style: italic;
        }

        .separator {
            height: 2.4rem;

            & + * {
                margin: 0;
            }
        }
    }

    /* IE10+ styles */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .body {
            margin: 0 5%;
        }
    }
}
