@use '../styles/mixins/colours';

.BoxedText {
    margin: 0;
    padding: 15px;
    padding-top: 0;
    padding-bottom: 7px;
    overflow-wrap: break-word;

    @media ($viewport-is-wide) {
        margin-top: 15px;

        .BoxedText-container {
            padding: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: #{colours.get-static('colour-bg-standard-tint-2')};
            background-color: var(--colour-bg-standard-tint-2);
        }
    }
}
