.BaseQuestion {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}
