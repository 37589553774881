.AddServicePage {
    .body {
        max-width: 90%;
        margin: auto;
    }

    iframe {
        width: 100%;
        height: 1000px;
        border: 0;
    }
}
