@use '../styles/mixins/colours';

.CrisisLineItem {
    margin: 6px 16px;
    padding-bottom: 10px;

    h3,
    h3 a {
        margin-top: 2px;
        margin-bottom: 0;
        color: #{colours.get-static('colour-crisis-white')};
        color: var(--colour-crisis-white);
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        min-height: 32px;

        &:visited {
            color: #{colours.get-static('colour-crisis-white')};
            color: var(--colour-crisis-white);
        }

        &:hover {
            text-decoration: none;
        }
    }

    .Contact.Phone {
        padding-top: 2px;

        p {
            display: inline;
            color: #{colours.get-static('colour-crisis-white')};
            color: var(--colour-crisis-white);
        }

        .details-container {
            color: var(--colour-crisis-white);
        }
    }

    .bonusCopy {
        padding: 0;
        font-size: 14px;

        li {
            padding-bottom: 1em;
            list-style-position: inside;
        }

        li:last-child {
            padding-bottom: 0;
        }
    }

    .Collapser {
        .Button {
            color: #{colours.get-static('colour-crisis-white')};
            color: var(--colour-crisis-white);
            font-weight: normal;
            font-size: 14px;

            .Icon {
                path {
                    stroke: #{colours.get-static('colour-crisis-white')};
                    stroke: var(--colour-crisis-white);
                }
            }

            .li {
                color: #{colours.get-static('colour-crisis-white')};
                color: var(--colour-crisis-white);
            }
        }

        margin-top: 5px;
    }
}

li.CrisisLineItem {
    display: inline-block;
    list-style-type: none;
}
