@use '../styles/mixins/colours';

.ToastMessage {
    font-weight: lighter;

    .action-container {
        display: flex;
        align-items: center;
        font-weight: bolder;
        column-gap: 7px;

        .close-toast-message-button {
            overflow: hidden;
        }

        svg {
            width: 25px;
            height: 25px;
            margin-bottom: -7px;

            path {
                fill: var(--colour-text-very-light);
            }
        }
    }

    button {
        background-color: transparent;
        border: 0;
        color: var(--raw-colour-warm-white);

        .icon {
            .CrossIcon {
                width: 55px;
                height: auto;
                margin: -15px -21px -24px -23px;

                path {
                    fill: #{colours.get-static('colour-text-very-light')};
                    fill: var(--colour-text-very-light);
                    stroke: #{colours.get-static('colour-text-very-light')};
                    stroke: var(--colour-text-very-light);
                }
            }
        }
    }

    @media (max-width: 400px) {
        .action-description-button {
            display: none;
        }
    }
}
