.SitesMap {
    // For min-height to work this must have a height assigned otherwise
    // children sized 100% won't expand annoyingly ¯\_(ツ)_/¯
    height: 0;
    min-height: 300px;

    /*
     * react-google-maps bizarrely seems to focus on the wrong marker
     * when clicked. No obvious fixes and react-google-maps is very
     * janky + not-supported so instead just hide the visual indicator
     * that a maker is focused on.
     */
    :focus {
        outline: none;
    }
}
