@use '../styles/mixins/colours';

.Accessibility {
    position: relative;
    color: #{colours.get-static('colour-text-dark')};
    color: var(--colour-text-dark);
    font-size: 16px;
    padding-bottom: 8px;

    svg {
        margin-left: -2px;
        margin-right: 6px;
        vertical-align: text-bottom;
    }
}
