@use '../styles/mixins/colours';

.LimitedServicesBanner {
    h2 {
        font-size: 1.17em;
    }

    .links {
        &.Link {
            color: #{colours.get-static('colour-text-very-light')};
            color: var(--colour-text-very-light);

            &:visited {
                color: #{colours.get-static('colour-text-very-light')};
                color: var(--colour-text-very-light);
            }
        }
    }
}
