@use '../styles/mixins/colours';

.branding-footer-container {
    @include colours.define(
        'colour-primary',
        'branding-footer-container',
        'raw-colour-rich-purple'
    );
    @include colours.define(
        'colour-primary-tint-1',
        'branding-footer-container',
        colours.lighten(
            'colour-primary',
            'branding-footer-container',
            -34%
        )
    );
    @include colours.define(
        'colour-primary-tint-2',
        'branding-footer-container',
        colours.lighten(
            'colour-primary',
            'branding-footer-container',
            -60%
        )
    );

    background-color: #{colours.get-static('colour-primary-tint-1', 'branding-footer-container')};
    background-color: var(--colour-primary-tint-1);
    color: #{colours.get-static('colour-text-light', 'branding-footer-container')};
    color: var(--colour-text-light);
    font-size: 0.9em;

    a {
        color: #{colours.get-static('colour-text-light', 'branding-footer-container')};
        color: var(--colour-text-light);

        &:visited {
            color: #{colours.get-static('colour-text-light', 'branding-footer-container')};
            color: var(--colour-text-light);
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            display: block;
            list-style-type: none;
            padding-top: 8px;
        }
    }

    h1 {
        font-size: 1em;
        font-weight: bold;
        color: #{colours.get-static('raw-colour-pure-white', 'branding-footer-container')};
        color: var(--raw-colour-pure-white);
    }

    .main-links,
    .other-content {
        padding: 0 40px;
    }

    .icon-link {
        margin-right: 10px;
        text-decoration: none;
    }

    .main-links {
        padding-top: 60px;
        display: grid;
        grid-template-areas:
            'additional-information homelessness-services about-links'
            'service-providers supporters socials';
        column-gap: 140px;
        row-gap: 20px;

        .additional-information {
            grid-area: additional-information;
        }

        .homelessness-services {
            grid-area: homelessness-services;
        }

        .about-links {
            grid-area: about-links;
        }

        .service-providers {
            grid-area: service-providers;
        }

        .supporters {
            grid-area: supporters;
        }

        .socials {
            li {
                display: inline;
            }

            grid-area: socials;
        }
    }

    .other-content {
        padding-top: 30px;
        padding-bottom: 40px;
        display: grid;
        grid-template-areas:
            'about acknowledgements'
            'other-acknowledgements other-acknowledgements';
        column-gap: 140px;
        row-gap: 60px;

        .about {
            grid-area: about;
        }

        .acknowledgements {
            grid-area: acknowledgements;

            .AboriginalFlagIcon,
            .TorresStraitIslandersFlagIcon {
                height: 40px;
                width: auto;
                margin-right: 10px;
            }
        }

        .other-acknowledgements {
            grid-area: other-acknowledgements;
            text-align: center;
        }

        .other-content-icon {
            height: 40px;
            width: auto;
            margin-right: 10px;
        }
    }

    @media only screen and (max-width: 680px) {
        .main-links {
            grid-template-areas:
                'additional-information homelessness-services'
                'about-links service-providers'
                'supporters socials';
            column-gap: 70px;
        }

        .other-content {
            column-gap: 70px;
        }
    }

    @media only screen and (max-width: 450px) {
        .main-links {
            grid-template-areas:
                'additional-information'
                'homelessness-services'
                'about-links'
                'service-providers'
                'supporters'
                'socials';
        }

        .other-content {
            grid-template-areas:
                'about'
                'acknowledgements'
                'other-acknowledgements';

            .acknowledgements {
                margin-top: 10px;
                grid-area: acknowledgements;

                p {
                    margin-top: 25px;
                }
            }
        }
    }
}
