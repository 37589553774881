@use '../styles/mixins/colours';

.LogoWithShadow {
    position: relative;
    width: 55px;
    height: 48px;

    .LogoLightIcon {
        transform: rotate(5deg);
    }

    .LogoShadowIcon {
        margin-top: 5px;
        margin-left: -4px;
        opacity: 0.2;
    }

    .LogoLightIcon,
    .LogoShadowIcon {
        position: absolute;
        top: -4px;
        right: -5px;
        width: 48px;
        height: 48px;

        path,
        circle {
            fill: #{colours.get-static('colour-text-dark')};
            fill: var(--colour-text-dark);
        }
    }
}
