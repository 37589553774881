@use '../styles/mixins/colours';

.ResultsList {
    > ol {
        padding-left: 0;
        margin: 0;

        > li {
            list-style-type: none;
        }
    }

    .ResultListItem ~ .ResultListItem {
        border-top: 10px solid #{colours.get-static('colour-bg-standard-tint-2')};
        border-top: 10px solid var(--colour-bg-standard-tint-2);
    }

    &.crisisResults {
        background-color: colours.get-static('colour-brand-primary-mid');
        background-color: var(--colour-brand-primary-mid);

        ol {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @media (max-width: 720px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 500px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
