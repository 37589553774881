@use '../styles/mixins/colours';

.TransportTime {
    display: block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;

    .travel-time {
        position: relative;
        padding: 0;
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);

        > span {
            display: inline-block;
            vertical-align: middle;

            svg {
                margin-left: -3px;
                vertical-align: text-bottom;
            }
        }

        span:nth-child(n+2) {
            margin-right: 1em;
            margin-left: 0.6em;
        }
    }

    .location {
        margin-right: 24px;
        color: #{colours.get-static('colour-text-mid')};
        color: var(--colour-text-mid);
    }

    .ResultListItem & {
        margin-bottom: 15px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;

        .travel-time {
            display: block;

            span:nth-child(n+2) {
                margin-right: 1em;
                margin-left: 0.3em;
            }
        }

        .Icon {
            width: 20px;
            height: 20px;
        }
    }
}
