@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-Light_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-LightItalic_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-LightItalic_Web.woff') format('woff');
	font-weight: 300;
	font-style: italic;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-Book_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-BookItalic_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-BookItalic_Web.woff') format('woff');
	font-weight: 400;
	font-style: italic;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-Medium_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-MediumItalic_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-MediumItalic_Web.woff') format('woff');
	font-weight: 500;
	font-style: italic;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-Bold_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Gotham Rounded';
	src: url('/fonts/hoc/GothamRnd-BoldItalic_Web.woff2') format('woff2'),
		url('/fonts/hoc/GothamRnd-BoldItalic_Web.woff') format('woff');
	font-weight: 700;
	font-style: italic;
    font-display: swap;
}

