@use '../styles/mixins/colours';

.ResultListItem {
    font-size: 14px;
    font-weight: 400;

    .name {
        display: block;
        margin-top: 5px;

        h2 {
            display: inline-block;
            margin: 0 0.5em 0 0;
            font-size: 18px;
            font-weight: 500;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .flags {
            display: inline-block;
            margin-bottom: 2px;
            vertical-align: middle;
        }
    }

    .title-container {
        display: flex;
        justify-content: space-between;

        .compare-share-container {
            display: flex;
            column-gap: 6px;

            .ShareButton {
                display: flex;
                line-height: 20px;
                vertical-align: middle;
                margin-top: 2px;
            }
        }
    }

    .Eligibility {
        padding-right: 0;
        padding-left: 0;
    }

    .location {
        position: relative;
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);

        svg {
            font-size: 1rem;
            margin: 2px 8px 2px 2px;
            vertical-align: middle;
        }
    }

    p {
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);
    }

    li {
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);
    }

    .site_name {
        margin-bottom: 15px;
        color: #{colours.get-static('colour-text-mid')};
        color: var(--colour-text-mid);
    }

    .Accessibility {
        margin-bottom: 15px;
        color: #{colours.get-static('colour-text-mid')};
        color: var(--colour-text-mid);
    }

    .CurrentOpeningTime {
        margin-bottom: 15px;

        svg {
            font-size: 1rem;
            margin: 2px;
            vertical-align: middle;
        }

        .until,
        .open {
            color: #{colours.get-static('colour-text-mid')};
            color: var(--colour-text-mid);
        }
    }

    .TransportTime {
        display: flex;
    }

    @media (max-width: 556px) {
        .TransportTime {
            display: flex;
            flex-direction: column;
        }

        .compare-share-container {
            .ShareButton {
                display: none;
            }
        }
    }
}
