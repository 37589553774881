@use '../styles/mixins/colours';

.Accordion {
    > ul {
        margin: 0;
        padding: 0;

        > li {
            border-bottom: 1px solid #{colours.get-static('colour-border-list-item-dark')};
            border-bottom: 1px solid var(--colour-border-list-item-dark);
            list-style: none;
        }
    }
}

.AccordionItem {
    summary {
        list-style: none;

        &::-webkit-details-marker {
            display: none; // # chrome doesn't support styling with list-style
        }

        &::before {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        div { // wrapper needed for safari flex bug https://bugs.webkit.org/show_bug.cgi?id=190065
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                margin: 0;
            }
        }
    }

    .Icon {
        margin: 10px; // Eliminates layout shift during rotation.
        transform: rotate(90deg);
        transition: transform 0.1s ease-out;
    }

    .AccordionItemContent {
        ul,
        ol {
            margin: 10px 0;
        }

        > ul {
            list-style-type: disc;

            li > ul {
                list-style-type: circle;
            }
        }
    }

    &[open] {
        .Icon {
            transform: rotate(-90deg);
        }
    }
}
