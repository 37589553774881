$max-width: 800px;
$viewport-is-wide: 'min-width: 800px';
$desktop-side-column-color: #c1c0ba;

body {
    max-width: $max-width;
    margin: auto;
    background-color: $desktop-side-column-color;
}

@media print {
    body {
        background-color: #fff;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.anchor {
    display: block;
    position: relative;
    top: -$appbar-height;
    overflow: auto;
    visibility: hidden;
}
