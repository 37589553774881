@use '../styles/mixins/colours';

.QuickExit {
    display: inline-block;
    height: $appbar-height;
    padding: 0 15px;
    background-color: #{colours.get-static('colour-irreversible-action', 'CrisisContactButton')};
    background-color: var(--colour-irreversible-action);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 #{colours.get-static('app-bar-shadow')};
    box-shadow: 0 0 12px 0 var(--app-bar-shadow);

    &.showBar {
        transition: margin 0.2s ease;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }

    span {
        line-height: $appbar-height;
    }

    .link {
        color: #{colours.get-static('colour-text-very-light')};
        color: var(--colour-text-very-light);
        text-decoration: none;

        &:visited {
            color: #{colours.get-static('colour-text-very-light')};
            color: var(--colour-text-very-light);
        }
    }
}
