// colors in this file are not used in the app, but are used in the
// print page so they should be as standard as possible
.MyListPrintPage {
    background-color: #fff;
    color: #000;
    padding: 40px;
    font-size: 14px;

    .my-list-print-header {
        display: flex;
        justify-content: space-between;

        .AskIzzyGreyScaleIcon {
            width: 130px;
            height: 70px;
        }

        .date-container {
            font-size: 10px;
        }
    }

    .Spacer {
        height: 3px;
        background-color: #000;
    }

    .my-list-print-footer {
        display: flex;
        justify-content: space-between;
        page-break-inside: avoid;
        column-gap: 10px;

        .my-list-print-about-title {
            font-size: 16px;
            font-weight: bold;
        }

        .my-list-print-about-description {
            margin-top: 10px;
        }

        .my-list-print-qr {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 12em;

            .my-list-print-qr-description {
                text-align: center;
                font-weight: bold;
            }

            .qr-code {
                width: 120px;
            }
        }

        .AskIzzyGreyScaleIcon {
            width: 200px;
            height: 100px;
        }
    }

    .need-an-interpreter {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .my-list-print-list {
        list-style-type: none;
        padding: 0;

        .my-list-print-list-item {
            border: 1px solid #808080;
            margin-top: 5px;
            page-break-inside: avoid;
            padding: 20px;

            .my-list-print-service-name {
                font-size: 16px;
                font-weight: bold;
            }

            .my-list-print-service-site {
                margin-top: 5px;
            }

            .my-list-print-service-ndis {
                margin-top: 10px;
                font-size: 12px;
            }

            .my-list-print-service-description {
                margin-top: 10px;
                font-size: 12px;
            }

            .Address {
                margin-top: 5px;
                font-size: 12px;

                .Address-wrapper {
                    color: #000;

                    .suburb {
                        margin-left: 3px;
                    }
                }
            }

            .my-list-print-service-phone {
                margin-top: 0;
                display: flex;
                column-gap: 20px;

                .my-list-print-phone-container {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    .PhoneSolidIcon {
                        width: 25px;
                        height: 25px;
                        margin-right: 4px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}
