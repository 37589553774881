.DebugPersonalisation {
    .layerHeading {
        h5 {
            display: inline-block;
            margin: 0.5em 0 0.2em;
        }
    }

    .Diff {
        margin-left: 1em;
    }
}
