@use '../styles/mixins/colours';

.tooltip-content {
    display: flex;
    flex-direction: row;

    .close-tooltip {
        background: transparent;
        border: 0;
        height: 20px;
        width: 20px;
        padding: 0;

        svg {
            width: 40px;
            height: 40px;
            margin: -8px 0 0 -11px;

            path {
                fill: var(--colour-text-dark);
            }
        }
    }

    ul {
        li {
            padding-bottom: 8px;
        }

        li:last-child {
            padding-bottom: 0;
        }
    }
}
