@use '../../src/styles/mixins/colours';

.ResultsMapPage {
    display: flex;
    flex-direction: column;
    height: 0;
    min-height: 90vh;

    .GeolocationButtonForTravelTimes {
        padding: 0;
        justify-content: flex-end;
    }

    .AppBarContainer {
        margin-bottom: 0;
    }

    .progress {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .map {
        flex: 1 0 200px;
        transition: flex-basis 0.3s;

        .SitesMap {
            height: 100%;
        }
    }

    .List {
        width: 100%;
        max-width: $max-width;
        padding: 0;
    }

    .ResultListItem {
        min-height: 150px;
        background-color: #{colours.get-static('raw-colour-pure-white')};
        background-color: var(--raw-colour-pure-white);
    }

    .ResultListItem + .ResultListItem {
        border-top: 10px solid #{colours.get-static('colour-bg-standard-tint-2')};
        border-top: 10px solid var(--colour-bg-standard-tint-2);
    }

    @media (max-width: 768px) {
        .GeolocationButtonForTravelTimes {
            .GeoLocationButton {
                width: auto;
            }
        }
    }

    @media (max-width: 600px) {
        .GeolocationButtonForTravelTimes {
            padding: 0;
            align-items: flex-end;
        }
    }
}
