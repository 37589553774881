@use '../styles/mixins/colours';

.siteBanner {
    &,
    &-notice,
    &-warning {
        padding: 0 15px;
        color: #{colours.get-static('colour-text-very-light')};
        color: var(--colour-text-very-light);
        overflow: auto;

        a {
            color: #{colours.get-static('colour-text-very-light')};
            color: var(--colour-text-very-light);

            .HomePage .header & {
                text-decoration: underline;
            }
        }
    }

    &,
    &-notice {
        background-color: #{colours.get-static('raw-colour-slate-gray')};
        background-color: var(--raw-colour-slate-gray);
    }

    &-warning {
        background-color: #{colours.get-static('raw-colour-rich-purple')};
        background-color: var(--raw-colour-rich-purple);
    }
}

// Adds correct spacing when banner is used on a results page
main > .ResultsListPage {
    .siteBanner {
        &,
        &-notice,
        &-warning {
            margin-bottom: 3rem;
        }
    }
}

// Fixes banner styling on service page due to css inheritance/cascading
main > .ServicePage {
    .siteBanner {
        &,
        &-notice,
        &-warning {
            margin: 1.5rem 0;
            font-size: 1rem;

            h3 {
                font-weight: revert;
            }
        }
    }
}
