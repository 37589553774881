@use '../../styles/mixins/colours';

.Paragraph {
    img {
        margin: 0 auto;

        &:only-child {
            display: block;
        }
    }

    .inlineIcon {
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
}
