@use '../styles/mixins/colours';

.CurrentOpeningTime {
    font-size: 16px;
    white-space: nowrap;

    > span {
        display: inline-block;
        vertical-align: middle;
        white-space: initial;
    }

    .open {
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);
    }

    .closed {
        font-weight: 500;
    }

    .until {
        max-height: 19px;
        margin-right: 1em;
        margin-left: 6px;
        overflow: hidden;

        .open,
        .closed {
            white-space: nowrap;
        }

        .time {
            white-space: nowrap;
        }
    }

    @media print {
        display: none;
    }
}

.ResultListItem .CurrentOpeningTime {
    font-size: 14px;

    .Icon {
        width: 24px;
        height: 24px;
    }
}
