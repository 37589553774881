@use '../styles/mixins/colours';

.Contact {
    @include clearfix;

    display: block;
    width: 100%;
    padding-top: 6px;

    .kind {
        width: 100%;
        max-width: 100px;
        color: #{colours.get-static('colour-text-mid')};
        color: var(--colour-text-mid);
        font-size: 14px;
    }

    a {
        display: block; /* For IE11 */
        display: grid;
        grid-template-columns: min-content auto;
        position: relative;
        width: auto;
        margin-top: 5px;
        padding: 6px;
        font-size: 16px;

        .Contact-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .Icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin: -10px -5px;
            vertical-align: bottom;
            text-decoration: none;

            path {
                fill: #{colours.get-static('colour-text-dark')};
                fill: var(--colour-text-dark);
            }
        }
    }

    @media print {
        color: #{colours.get-static('raw-colour-pure-black')};
        color: var(--raw-colour-pure-black);

        a {
            text-decoration: none;
            padding: 1px;

            .Contact-text {
                text-overflow: ellipsis;
                overflow: hidden;
                overflow-wrap: break-word;
                white-space: normal;
                margin-top: 3px;
                color: var(--raw-colour-pure-black);
                padding-left: 8px;
            }

            .Icon {
                display: inline-block;
                width: 40px;
                height: 40px;
                margin: -10px -5px;
                vertical-align: bottom;

                path {
                    fill: #{colours.get-static('colour-text-dark')};
                    fill: var(--colour-text-dark);
                }
            }
        }
    }
}
