@use '../styles/mixins/colours';

.MyListPage {
    display: flex;
    flex-direction: column;

    .progress {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        & > * {
            display: block;
            width: 57px;
            height: 57px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .other-mobile-features-container {
        display: flex;
        flex-direction: row;
        column-gap: 8px;

        .ShareButton {
            background-color: transparent;

            .SvgIcon {
                width: 28px;
                height: 28px;

                path {
                    fill: #{colours.get-static('colour-text-brand')};
                    fill: var(--colour-text-brand);
                }
            }
        }

        .PrintButton {
            .print-component-button {
                background-color: transparent;

                .SvgIcon {
                    width: 32px;
                    height: 32px;

                    path {
                        fill: #{colours.get-static('colour-text-brand')};
                        fill: var(--colour-text-brand);
                    }
                }
            }
        }
    }

    .top-button-container {
        &.web {
            margin: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .count-container {
            color: #{colours.get-static('colour-text-mid')};
            color: var(--colour-text-mid);

            &.mobile {
                margin: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .clear-all {
            border: 0;
            font-weight: bolder;
            background-color: transparent;
            color: #{colours.get-static('colour-text-mid')};
            color: var(--colour-text-mid);
        }

        .Spacer {
            margin: 10px 0;
        }

        .clear-all-container {
            display: flex;
            column-gap: 20px;

            &.mobile {
                justify-content: right;

                .clear-all {
                    margin: 15px;
                }
            }
        }
    }

    .instructions {
        font-size: 14px;
    }

    .empty-list-container {
        text-align: center;
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);
        margin: 100px 0;

        .back-to-home {
            background-color: #{colours.get-static('colour-brand-primary')};
            background-color: var(--colour-brand-primary);
            color: #{colours.get-static('colour-text-very-light')};
            color: var(--colour-text-very-light);
            height: 40px;
            margin: 20px auto;
            border-radius: 5px;
            border: 0;
        }
    }

    .my-list-results-container {
        .save-list {
            text-align: center;
            padding: 15px;

            &.top {
                color: #{colours.get-static('colour-text-dark')};
                color: var(--colour-text-dark);

                &.mobile {
                    display: none;
                }

                .save-list-button {
                    color: #{colours.get-static('colour-brand-primary')};
                    color: var(--colour-brand-primary);
                }
            }

            &.bottom {
                background-color: #{colours.get-static('colour-bg-opposite-light')};
                background-color: var(--colour-bg-opposite-light);
                color: #{colours.get-static('raw-colour-pure-white')};
                color: var(--raw-colour-pure-white);
            }

            .save-list-button {
                border: 0;
                background-color: transparent;
                font-weight: 600;
            }
        }
    }

    .GeolocationButtonForTravelTimes {
        padding: 0;
        justify-content: flex-end;
    }

    .map {
        flex: 1 0 200px;
        transition: flex-basis 0.3s;

        .SitesMap {
            height: 100%;
        }
    }

    .List {
        width: 100%;
        max-width: $max-width;
        padding: 0;
    }

    .information-container {
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);

        .information-text {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            align-items: center;
            padding: 0 60px;
            margin-bottom: 90px;
        }
    }

    @media (max-width: 500px) {
        .my-list-results-container {
            .save-list {
                &.top {
                    &.web {
                        display: none;
                    }

                    &.mobile {
                        display: block;
                    }
                }
            }
        }
    }
}
