@use '../styles/mixins/colours';

.AlertBanner {
    padding: 0.1px; /* force margins not to collapse */
    background-color: #{colours.get-static('colour-bg-standard')};
    background-color: var(--colour-bg-standard);
    color: #{colours.get-static('colour-text-dark')};
    color: var(--colour-text-dark);
    list-style: none;

    summary::marker,
    summary::-webkit-details-marker {
        display: none;
    }

    // this one is to hide the arrow for the IE polyfilled details
    summary::before {
        display: none;
    }

    summary:hover {
        cursor: pointer;
    }

    // The ":focus-within" pseudo class is not recognised by IE11 so we polyfill
    // it with some JS that'll attach a ".focus-within" class as a substitute.
    // Since browsers will ignore any block with any CSS selector it doesn't
    // recognise in the list we need use the polyfill'ed class selector as it's
    // own block so it doesn't get ignored. We use a Sass mixin to avoid
    // duplication.
    @mixin focus-within {
        outline: 1px solid #{colours.get-static('colour-input-focus-primary')};
        outline: 1px solid var(--colour-input-focus-primary);

        summary {
            outline: 0;
        }
    }

    &.hasBody:focus-within {
        @include focus-within;
    }

    &.hasBody.focus-within {
        @include focus-within;
    }

    a {
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);
    }

    &[open] {
        .title-container .ChevronIcon {
            transform: rotate(90deg);
        }
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
        padding: 0 0 0 1em;

        .title {
            display: flex;
            align-items: center;

            .icon {
                .InfoIcon {
                    width: 40px;
                    height: auto;
                    margin: -8px -7px -13px -13px;
                }

                .WarningIcon {
                    width: 23px;
                    height: auto;
                    margin: -15px 1px -13px -5px;
                }
            }

            .text {
                margin: 1em 1em 1em 0.4em;
                font-size: 18px;

                > :first-child {
                    margin-top: 0;
                }

                > :last-child {
                    margin-bottom: 0;
                }
            }
        }

        .ChevronIcon {
            transform: rotate(0deg);
            transition: transform 0.1s ease-out;
        }
    }

    .body {
        margin: 0 1em 1em;

        > :first-child {
            margin-top: 0;
        }
    }
}
