@use 'sass:color';
@use '../styles/mixins/colours';

.Contact {
    .details-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-top: 7px;

        p {
            margin: 0;
        }

        .crisis-line-details {
            display: flex;

            svg {
                width: 35px;
                height: 35px;
                margin: -12px -10px;

                path {
                    fill: var(--colour-crisis-white);
                }
            }
        }
    }

    .ResultsList & {
        .ContactButton {
            display: block;
            text-align: center;
            max-width: 230px;
            max-height: 200px;
        }
    }

    .ContactLink,
    .ContactButton {
        svg {
            margin-right: 6px;
            margin-bottom: 4px;
            vertical-align: middle;
        }
    }

    .ContactButton {
        background-color: var(--colour-brand-primary);
        color: var(--colour-text-very-light);
        border-radius: 5px;
        padding: 10px;
        text-decoration: none;

        .Contact-text {
            display: inline;
            font-weight: bold;
            font-size: 17px;
            color: #{colours.get-static('colour-crisis-white')};
        }

        svg {
            path {
                fill: #{colours.get-static('colour-crisis-white')};
                fill: var(--colour-crisis-white);

                @media print {
                    fill: #{colours.get-static('raw-colour-pure-black')};
                    fill: var(--raw-colour-pure-black);
                }
            }
        }

        &.styleHollow {
            .ContactButton {
                background-color: #{colours.get-static('colour-button-primary')};
                color: #{colours.get-static('colour-button-primary')};
                color: var(--colour-button-primary);
                border: 1px solid #{colours.get-static('colour-button-primary')};
                border: 1px solid var(--colour-button-primary);
                height: 25px;

                @media print {
                    border: 0;
                }
            }
        }

        .call-text + .number.value {
            font-size: initial;
        }
    }
}
