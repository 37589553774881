.FormSection {
    legend {
        font-size: 1.4em;
        width: unset;
        height: unset;
        position: unset;
        clip: unset;
        margin: 0.7em 0 0;
        font-weight: bold;
    }
}
