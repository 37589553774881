@use '../../styles/mixins/colours';

.Link {
    color: #{colours.get-static('colour-link')};
    color: var(--colour-link);

    &:visited {
        color: #{colours.get-static('colour-link-visited')};
        color: var(--colour-link-visited);
    }

    &:hover {
        text-decoration: none;
    }
}
