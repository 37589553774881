@use '../styles/mixins/colours';

.AlertBannerList {
    margin-top: 0.5em;

    .alert-container {
        overflow: hidden;
        margin: 15px;
        margin-top: 13px;
    }

    .AlertBannerButton {
        background-color: #{colours.get-static('colour-bg-alert')};
        background-color: var(--colour-bg-alert);
        border-radius: 4px;
        padding: 10px 20px;
        margin-top: 4px;
        margin-right: 15px;
        border: 0;
        font-size: 18px;

        .InfoIcon {
            width: 40px;
            height: auto;
            margin: -8px -7px -13px -13px;
        }

        .AlertBannerContent {
            display: flex;
            align-items: center;
            column-gap: 12px;
        }
    }

    .AlertListTitleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 1em;
        background-color: #{colours.get-static('colour-bg-alert')};
        background-color: var(--colour-bg-alert);
        border-radius: 4px 4px 0 0;

        .AlertListTitle {
            display: flex;
            align-items: center;

            .icon {
                .InfoIcon {
                    width: 40px;
                    height: auto;
                    margin: -8px -7px -13px -13px;
                }
            }

            .text {
                margin: 1em 1em 1em 0.4em;
                font-size: 18px;
            }
        }

        .DismissAlertList {
            margin-right: 7px;
            border: 0;
            background-color: transparent;
            overflow: hidden;
            width: 20px;

            .icon {
                .CrossIcon {
                    width: 55px;
                    height: auto;
                    margin: -7px -12px -12px -27px;
                }
            }
        }
    }

    ul {
        padding-left: 0;
        margin: 0;
        border: #{colours.get-static('colour-border-list-item-gray')} solid 1px;
        border: var(--colour-border-list-item-gray) solid 1px;
        border-radius: 5px;

        > li {
            list-style-type: none;
            text-align: left;
            border-bottom: #{colours.get-static('colour-border-list-item-gray')} solid 1px;
            border-bottom: var(--colour-border-list-item-gray) solid 1px;

            &:last-child {
                border-bottom: 0;
                border-radius: 0 0 4px 4px;

                .AlertBanner {
                    border-radius: 0 0 4px 4px;
                }
            }
        }
    }

    &.inline {
        .AlertBanner {
            margin: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
        }

        li:last-child {
            .AlertBanner {
                border-bottom: 0;
            }
        }
    }

    &:not(.inline) {
        .AlertBanner {
            &.hasBody:focus-within {
                outline-width: 2px;
            }
        }
    }

    .warn {
        background-color: #{colours.get-static('colour-bg-alert')};
        background-color: var(--colour-bg-alert);
    }
}
