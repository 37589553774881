.LoadingResultsHeader {
    .primary {
        h1 {
            font-size: 32px;
            font-weight: bold;
        }
    }

    .homeLink {
        margin-right: 20px;
    }
}

.LogoHeader {
    h1 {
        font-size: 32px;
    }
}
