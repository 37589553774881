@use '../styles/mixins/colours';

.MyListButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    text-decoration: none;
    font-size: 16px;

    .list-button {
        grid-area: list-button;
        background-color: transparent;
        border: 0;

        svg {
            margin-top: 4px;

            path {
                fill: #{colours.get-static('colour-crisis-white')};
                fill: var(--colour-crisis-white);
            }
        }
    }

    .title {
        grid-area: title;
        color: #{colours.get-static('colour-text-very-light')};
        color: var(--colour-text-very-light);
        padding: 0 4px;
    }

    .my-list-count {
        grid-area: count;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #{colours.get-static('colour-bg-standard')};
        background-color: var(--colour-bg-standard);
        color: #{colours.get-static('colour-text-dark')};
        color: var(--colour-text-dark);
    }

    @media (max-width: 500px) {
        .title {
            display: none;
        }
    }
}
