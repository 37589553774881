@use '../styles/mixins/colours';

.MyListResults {
    background-color: #{colours.get-static('colour-bg-standard-tint-2')};
    background-color: var(--colour-bg-standard-tint-2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > ol {
        padding-left: 0;
        margin: 0;

        > li {
            list-style-type: none;
        }
    }

    &.crisisResults {
        background-color: colours.get-static('colour-brand-primary-mid');
        background-color: var(--colour-brand-primary-mid);

        ol {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @media (max-width: 720px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 500px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
