@use '../../../src/styles/mixins/colours';

.Location {
    .done-button {
        margin: 20px auto;
        width: 65%;

        .doneButton {
            width: 100%;
        }
    }

    .search {
        position: relative;
        width: 65%;
        margin: 20px auto;
        box-sizing: border-box;

        .Input {
            width: 100%;
            flex-wrap: wrap;

            .clear-text {
                top: 11px;

                &:focus {
                    outline: 1px solid #{colours.get-static('colour-brand-primary')};
                    outline: 1px solid var(--colour-brand-primary);
                }
            }

            input {
                border: 1px solid #{colours.get-static('colour-border-alert')};
                border: 1px solid var(--colour-border-alert);

                &:focus {
                    outline: none;
                }
            }

            &.isAutocompleteSuggestions input {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border: 0;
                outline: 1px solid #{colours.get-static('colour-brand-primary')};
                outline: 1px solid var(--colour-brand-primary);
            }

            &.isAutocompleteSuggestions ul {
                outline: 1px solid #{colours.get-static('colour-brand-primary')};
                outline: 1px solid var(--colour-brand-primary);

                button:focus {
                    outline: none;
                }
            }
        }

        .autocompleteList {
            position: relative;

            &.initialSuggestions {
                li {
                    padding: 0;
                    border: 0;
                    margin: 0.5em 0.7em;
                }

                li[aria-selected=true] {
                    background-color: transparent;
                }
            }

            li {
                display: block;
                border-bottom: 1px solid #{colours.get-static('colour-brand-primary')};
                border-bottom: 1px solid var(--colour-brand-primary);
                padding: 0.5em 0.7em;

                .state {
                    font-size: 14px;
                    color: #{colours.get-static('colour-text-mid')};
                    color: var(--colour-text-mid);
                }
            }

            li[aria-selected=true] {
                background-color: #{colours.get-static('colour-bg-standard-tint-3')};
                background-color: var(--colour-bg-standard-tint-3);
            }
        }
    }

    .done {
        text-align: center;
        margin: 2em 0;

        .doneButton {
            display: block;
            width: calc(100% - 40px);
            margin: 20px auto;
        }
    }

    .GeoLocationButtonContainer {
        margin: 0;
        display: flex;
        align-items: center;

        .LocationButton {
            color: #{colours.get-static('colour-text-dark')};
            color: var(--colour-text-dark);
        }

        .or {
            color: #{colours.get-static('colour-text-mid')};
            color: var(--colour-text-mid);
        }
    }

    .Spacer {
        margin: 0 1em;
    }

    .explainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 5px 5px 15px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: normal;
        color: #{colours.get-static('colour-text-mid')};
        color: var(--colour-text-mid);
        background-color: #{colours.get-static('colour-bg-standard-tint-3')};
        background-color: var(--colour-bg-standard-tint-3);

        .explainerIcons {
            display: inline-flex;
            margin-right: 1em;
            margin-bottom: -2px;

            .SvgIcon {
                margin-left: -1px;
                margin-right: -1px;

                &.WalkIcon {
                    width: 20px;
                }

                path {
                    fill: #{colours.get-static('colour-text-mid')};
                    fill: var(--colour-text-mid);
                }

                circle {
                    fill: #{colours.get-static('colour-text-mid')};
                    fill: var(--colour-text-mid);
                }
            }
        }
    }

    @media (max-width: 556px) {
        .search {
            width: 90%;
        }

        .done-button {
            width: 90%;
        }
    }
}
