@use '../styles/mixins/colours';

.LogoWithTextBoxContainer.Link {
    text-decoration: none;
    color: unset;

    .showAsLink {
        color: #{colours.get-static('colour-link')};
        color: var(--colour-link);
        text-decoration: underline;
    }

    &:visited .showAsLink {
        color: #{colours.get-static('colour-link-visited')};
        color: var(--colour-link-visited);
    }

    &:hover .showAsLink {
        text-decoration: none;
    }
}

.LogoWithTextBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background-color: #{colours.get-static('colour-bg-standard-tint-2')};
    background-color: var(--colour-bg-standard-tint-2);
    cursor: pointer;

    .Icon {
        width: 64px;
        height: 64px;

        .IconBorder {
            width: 64px;
            height: 64px;
            border-radius: 50%;

            .middle {
                padding: 11px;
            }
        }
    }

    .Content {
        width: 80%;
        margin-top: 20px;
        text-align: center;

        .Header {
            margin-bottom: 20px;
            font-size: 1.15em;
            font-weight: 500;
        }

        .Instruction {
            padding-bottom: 10px;
            font-size: small;
        }

        .Chevron {
            display: inline;
            font-weight: normal;
        }

        @media (max-width: 768px) {
            & {
                width: 100%;
            }
        }
    }
}
