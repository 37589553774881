@use '../styles/mixins/colours';

.Ndis {
    position: relative;
    padding-left: 32px;
    color: #{colours.get-static('colour-text-dark')};
    color: var(--colour-text-dark);
    font-size: 16px;
    font-weight: 500;
}
