@use '../styles/mixins/colours';

.MyListResultItem {
    font-size: 14px;
    font-weight: 400;
    background-color: #{colours.get-static('colour-bg-standard')};
    background-color: var(--colour-bg-standard);
    margin: 5px 10px;
    border-radius: 10px;

    .name {
        display: block;
        margin-top: 5px;

        h2 {
            display: inline-block;
            margin: 0 0.5em 0 0;
            font-size: 18px;
            font-weight: 500;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .my-list-result-title-container {
        display: flex;
        justify-content: space-between;
    }

    .my-list-result-content-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 20px;

        .my-list-result-information {
            .location {
                color: #{colours.get-static('colour-text-dark')};
                color: var(--colour-text-dark);
                font-weight: 500;

                svg {
                    margin: 2px;
                    font-size: 1rem;
                    vertical-align: middle;
                    margin-right: 8px;
                }
            }

            p {
                color: #{colours.get-static('colour-text-dark')};
                color: var(--colour-text-dark);
            }

            li {
                color: #{colours.get-static('colour-text-dark')};
                color: var(--colour-text-dark);
            }

            .site_name {
                margin-bottom: 15px;
                color: #{colours.get-static('colour-text-mid')};
                color: var(--colour-text-mid);
            }

            .CurrentOpeningTime {
                margin-bottom: 15px;

                svg {
                    font-size: 1rem;
                    margin: 2px;
                    vertical-align: middle;
                }

                .until,
                .open {
                    color: #{colours.get-static('colour-text-mid')};
                    color: var(--colour-text-mid);
                }
            }
        }
    }

    @media (max-width: 700px) {
        .my-list-result-content-container {
            flex-direction: column;

            .ResultListItemContact {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 5px;

                .contact-text {
                    padding: 5px;

                    .number.value {
                        display: none;
                    }
                }

                .contact-other {
                    align-items: flex-start;
                    margin-top: 0;

                    .GoogleMapsLink {
                        padding: 8px;
                    }
                }
            }
        }
    }
}
