@use '../styles/mixins/colours';

.ContactMethods {
    padding-bottom: 100px;

    .collapser-message {
        display: block;

        &:visited {
            color: #{colours.get-static('colour-link')};
            color: var(--colour-link);
        }
    }

    .detailsContainer {
        p {
            color: #{colours.get-static('colour-text-mid')};
            color: var(--colour-text-mid);
        }
    }
}
