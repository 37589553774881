@use '../src/styles/mixins/colours';

.SuggestionBox {
    display: flex;
    justify-content: flex-start;
    padding: 1em 1em 2em;
    background-color: #{colours.get-static('colour-bg-standard-tint-2')};
    background-color: var(--colour-bg-standard-tint-2);

    .SuggestionContentBox {
        h3 {
            color: #{colours.get-static('colour-text-dark')};
            color: var(--colour-text-dark);
            font-weight: 550;
        }

        h4 {
            margin-bottom: 5px;
            color: #{colours.get-static('colour-text-dark')};
            color: var(--colour-text-dark);
            font-weight: normal;
        }

        a {
            &:visited {
                color: #{colours.get-static('colour-link')};
                color: var(--colour-link);
            }
        }
    }

    &.withBackground {
        background-color: #{colours.get-static('colour-bg-standard-tint-2')};
        background-color: var(--colour-bg-standard-tint-2);
    }

    &.withoutBackground {
        background-color: #{colours.get-static('colour-bg-standard')};
        background-color: var(--colour-bg-standard);
    }
}
