.Email {
    font-size: 16px;

    svg {
        width: 30px;
        font: bold;
        text-align: center;
        display: block;
    }
}
