@use '../styles/mixins/colours';

.ShareButton {
    padding: 0;
    background-color: transparent;
    border: 0;

    .main-container {
        display: flex;
        align-items: center;

        .text-description {
            padding: 0 8px;
            font-weight: bold;
        }

        .SvgIcon {
            margin-bottom: -3px;
        }
    }
}
