/*
 * From twitter bootstrap
 */
// TODO: Refactor to use "&:not(:focus-within)" when all browsers we support
// support ":not"
.ScreenReader {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    &:focus-within {
        position: relative;
        width: auto;
        height: auto;
        margin: auto;
        padding: auto;
        border: 0;
        overflow: auto;
        clip: auto;
    }

    // .focus-within class is created by focus-within polyfill
    // we must duplicate the above line since the browser will
    // ignore a whole block if it sees any CSS selectors it
    // doesn't recognise.
    &.focus-within {
        position: relative;
        width: auto;
        height: auto;
        margin: auto;
        padding: auto;
        border: 0;
        overflow: auto;
        clip: auto;
    }
}
